import React from 'react';
import logo from './logo.svg';
import menuBoard from './menu-board.svg';
import menu from './menu.json';
import './App.css';
import { Col, Container, Row } from 'react-bootstrap';

function App() {
  return (
    <Container fluid className="App">
      {/* <img
          className="cafe-hills-menu-board"
          src={menuBoard}
          alt="cafe hills menu board"
        /> */}
      <Row>
        <Col sm={4} md={5} lg={4}>
          <div className='position-relative'>
            <img src={logo} className="cafe-hills-logo" alt="logo" />
          </div>
        </Col>
        <Col sm={8} md={7} lg={8}>
          <Row>
            {menu.map((category) => (
              <Col sm={4} className='pb-4'>
                <h5 className='text-capitalize'>{category.heading}</h5>
                <div className="d-flex flex-column gap-2">
                  {category.items.map((item) => (
                    <div className="d-flex justify-content-between">
                      <label className='text-capitalize'>{item.name}</label>
                      <label>
                        {'₹'}
                        {item.price}
                      </label>
                    </div>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
